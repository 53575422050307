import Router from 'next/router';
import { useEffect, useState } from 'react';
const Page = () => {
  const goHome = () => {
    Router.replace('/');
  };
  let [count, setCount] = useState(5);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count--);
      if (count === 0) {
        goHome();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="web-container  h-full">
      <div className="not-container flex flex-col items-center justify-center h-full">
        {/* <img src="@/assets/images/404.png" className="not-img" alt="404" /> */}
        <div className="not-detail mt-10">
          <h2 className="mb-4 font-bold text-lg">404</h2>
          <h4 className="mb-4">抱歉，您访问的页面不存在~🤷‍♂️🤷‍♀️</h4>
          <h4 className="mb-4">
            {count}秒后自动跳转到首页
            <span className="text-primary cursor-pointer" onClick={goHome}>
              {' '}
              马上跳转{' '}
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};
export default Page;
